<i *ngIf="!menuActive" class="fas fa-bars openMenu" [ngClass]="theme" (click)="menuActive = true"></i>
<i *ngIf="menuActive" class="fas fa-times openMenu active" [ngClass]="theme" (click)="menuActive = false"></i>

<div id="dark-mode-toggle">
  <i class="fas fa-sun"></i>
  <mat-slide-toggle color="primary" (change)="setDarkMode($event.checked)" [checked]="theme === 'dark-mode'"></mat-slide-toggle>
  <i class="fas fa-moon"></i>
</div>

<div id="menu" [ngClass]="{active: menuActive, 'dark-mode': theme === 'dark-mode'}" class="row justify-content-center">
  <button (click)="menuActive = false" *ngIf="menuActive" mat-raised-button color="secondary" [routerLink]="['']" class="homeBtn mx-md-5 mx-1">Home</button>
  <button (click)="menuActive = false" *ngIf="menuActive" mat-raised-button color="secondary" [routerLink]="['about']" class="mx-md-5 mx-1">About</button>
  <button (click)="menuActive = false" *ngIf="menuActive" mat-raised-button color="secondary" [routerLink]="['s_visualizer']" class="mx-md-5 mx-1">Sorting Visualizer</button>
  <button (click)="menuActive = false" *ngIf="menuActive" mat-raised-button color="secondary" [routerLink]="['hueshifter']" class="mx-md-5 mx-1">Hue Shifter</button>
  <button (click)="menuActive = false" *ngIf="menuActive" mat-raised-button color="secondary" [routerLink]="['gallery']" class="mx-md-5 mx-1">Gallery</button>
</div>
