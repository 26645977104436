import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {HomeComponent} from './components/home/home.component';
import {AboutComponent} from './components/about/about.component';
import {SortVisualizerComponent} from './components/sort-visualizer/sort-visualizer.component';
import {ArtDisplayComponent} from './components/art-display/art-display.component';
import {HueShifterComponent} from './components/hue-shifter/hue-shifter.component';


const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'about',
    component: AboutComponent
  },
  {
    path: 's_visualizer',
    component: SortVisualizerComponent
  },
  {
    path: 'gallery',
    component: ArtDisplayComponent
  },
  {
    path: 'hueshifter',
    component: HueShifterComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
