import {Component, HostBinding, Inject} from '@angular/core';
import {ThreeService} from '../../services/three.service';
import {Color, PerspectiveCamera, Scene, WebGLRenderer} from 'three';
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls';
import {Constants} from '../../constants';
import {gsap} from 'gsap';
import {NavigationEnd, Router} from '@angular/router';

@Component({
  selector: 'app-three',
  templateUrl: './three.component.html',
  styleUrls: ['./three.component.scss']
})
export abstract class ThreeComponent {
  protected scene;
  protected camera;
  protected renderer: WebGLRenderer;
  private controls;
  private previousTheme;

  protected constructor(protected threeService: ThreeService, protected router: Router, @Inject(Boolean) private debug = false) {
    const subscription = router.events.subscribe(
      (event) => {
        if ( event instanceof NavigationEnd && this.scene !== undefined) {
          this.scene.remove.apply(this.scene, this.scene.children);
          this.renderer.setSize(0, 0);
          this.renderer.clear();
          this.renderer.dispose();
          subscription.unsubscribe();
        }
      });
  }

  @HostBinding('class')
  get theme() {
    const theme = (localStorage.getItem('colorTheme') || 'light');
    if (this.previousTheme !== theme) {
      this.previousTheme = theme;
      this.swapColorPalette();
    }
    return theme + '-mode';
  }

  protected initialize(): void {
    this.scene = new Scene();
    this.scene.background = new Color(Constants.backgroundColor[localStorage.getItem('colorTheme') || 'light']);
    this.camera = new PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    this.renderer = new WebGLRenderer({antialias: true, canvas: document.getElementById('canvas') as HTMLCanvasElement});
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    this.threeService.attachResizeListener(this.camera, this.renderer);
    this.renderer.render(this.scene, this.camera);
    if (this.debug) {
      this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    }
    this.animate();
  }

  protected animate() {
    if (this.debug) {
      this.controls.update();
    }
    this.renderer.render(this.scene, this.camera);
    window.requestAnimationFrame(() => this.animate());
  }

  protected swapColorPalette() {
    const newBackgroundColor = new Color(Constants.backgroundColor[(localStorage.getItem('colorTheme') || 'light')]);
    gsap.to(this.scene.background, {duration: 0.5, r: newBackgroundColor.r, g: newBackgroundColor.g, b: newBackgroundColor.b});
  }

}
