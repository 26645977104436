<mat-slider
  thumbLabel
  tickInterval="1"
  step="1"
  min="25"
  max="100"
  aria-label="units" (change)="updateSlider($event.value)" [ngClass]="running ? 'hidden' : ''"></mat-slider>

<button mat-raised-button (click)="shuffle()" class="shuffle-btn" color="secondary" [ngClass]="running ? 'hidden' : ''">
  Shuffle
</button>

<div [ngClass]="running ? 'hidden' : ''">
  <div class="algo-select-box" [ngClass]="theme">
    <mat-form-field appearance="standard" class="algo-select">
      <mat-label>Choose a sorting algorithm</mat-label>
      <mat-select (selectionChange)="setSortingAlgo($event.value)">
        <mat-option value="naive">Naive sort</mat-option>
        <mat-option value="bubble">Bubble sort</mat-option>
        <mat-option value="quick">Quick sort</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>

<button mat-raised-button (click)="start()" class="start-btn" color="primary" [ngClass]="running ? 'hidden' : ''">
  Start
</button>

<button mat-raised-button (click)="stop()" class="stop-btn" color="primary" [ngClass]="!running ? 'hidden' : ''">
  Stop
</button>

<div id="counter" [ngClass]="{'dark-mode': theme === 'dark-mode'}">
  <h1>Number of Operations</h1>
  <h1 class="operations">{{operations}}</h1>
</div>
