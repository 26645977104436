import {Injectable} from '@angular/core';
import {PerspectiveCamera, Scene, WebGLRenderer} from 'three';
import {Constants} from '../constants';

@Injectable({
  providedIn: 'root'
})
export class ThreeService {

  constructor() {
  }

  public attachResizeListener(camera: PerspectiveCamera, renderer: WebGLRenderer) {
    window.addEventListener('resize', () => {
      const width = window.innerWidth;
      const height = window.innerHeight;

      // Update camera
      camera.aspect = width / height;
      camera.updateProjectionMatrix();

      // Update renderer
      renderer.setSize(width, height);
      renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    });
  }
}
