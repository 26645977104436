import {Component, OnInit} from '@angular/core';
import {ThreeComponent} from '../three/three.component';
import {ThreeService} from '../../services/three.service';
import {BoxGeometry, Color, Mesh, MeshBasicMaterial, PointLight, Raycaster} from 'three';
import {Router} from '@angular/router';
import {Constants} from '../../constants';
import {gsap} from 'gsap';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent extends ThreeComponent implements OnInit {

  private geometry = new BoxGeometry(2, 0.1, 1);
  private cubes = [];
  private cubesInit = [];
  private basicCubes = [];
  private raycaster = new Raycaster();

  constructor(protected threeService: ThreeService, protected router: Router) {
    super(threeService, router, false);
  }

  ngOnInit(): void {
    super.initialize();
    window.addEventListener('mousedown', this.onMouseClick.bind(this));
    this.camera.position.set(0, 0, 10);
    this.camera.lookAt(0, 0, 0);
    for (let i = -8; i < 8; i++) {
      for (let j = -8; j < 8; j++) {
        let color;
        const val = Math.random();
        if (val < 0.9) {
          color = Constants.meshColour[localStorage.getItem('colorTheme') || 'light'];
        } else if (val < 0.94) {
          color = 0x8c6d0e;
        } else if (val < 0.98) {
          color = 0x8a3333;
        } else {
          color = 0x0e6a8c;
        }
        const cube = new Mesh(this.geometry, new MeshBasicMaterial({color}));
        cube.position.set(i * 2.3, j * 1.2, 0);
        this.scene.add(cube);
        this.cubes.push(cube);
        this.cubesInit.push(cube);
        if (val < 0.9) {
          this.basicCubes.push(cube);
        }
      }
    }
    this.cubesInit = this.cubesInit.sort((a, b) => Math.random() * 2 - 1);
  }

  protected swapColorPalette() {
    super.swapColorPalette();
    const newColor = new Color(Constants.meshColour[(localStorage.getItem('colorTheme') || 'light')]);
    // @ts-ignore
    this.basicCubes.forEach(cube => gsap.to(cube.material.color, {duration: 0.5, r: newColor.r, g: newColor.g, b: newColor.b}));
  }

  protected animate() {
    if (this.cubesInit.length > 0) {
      const cube = this.cubesInit.pop();
      gsap.to(cube.rotation, {duration: 2, x: 1.6, delay: .1, ease: 'expo.out'});
    } else if (this.cubes.length > 0 && Math.random() < 0.001) {
      const cube = this.cubes[Math.floor(Math.random() * this.cubes.length)];
      cube.rotation.x = 1.6;
      gsap.to(cube.rotation, {duration: 2, x: 11.2, delay: .1, ease: 'expo.out'});
      gsap.to(cube.rotation, {duration: 2, x: 1.6, delay: 2.1, ease: 'elastic.out'});
    }
    super.animate();
  }

  private onMouseClick(event: MouseEvent) {
    const mouseX = (event.clientX / window.innerWidth) * 2 - 1;
    const mouseY = -(event.clientY / window.innerHeight) * 2 + 1;
    this.raycaster.setFromCamera({x: mouseX, y: mouseY}, this.camera);
    for (const intersect of this.raycaster.intersectObjects(this.scene.children, true)) {
      gsap.to(intersect.object.rotation, {duration: 2, x: 30, delay: .1, ease: 'expo.out'});
      gsap.to(intersect.object.rotation, {duration: 2, x: 1.6, delay: 2.1, ease: 'elastic.out'});
    }
  }

}
