import {Component, HostBinding, OnInit} from '@angular/core';
import {ThreeService} from '../../services/three.service';
import {ThreeComponent} from '../three/three.component';
import {BoxGeometry, Color, Mesh, MeshLambertMaterial, PointLight, Raycaster, Vector3} from 'three';
import {gsap} from 'gsap';
import {Constants} from '../../constants';
import {Router} from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent extends ThreeComponent implements OnInit {

  private raycaster = new Raycaster();
  private cubes: Mesh[] = [];

  constructor(protected threeService: ThreeService, protected router: Router) {
    super(threeService, router, false);
  }

  ngOnInit(): void {
    super.initialize();
    this.camera.position.set(0, 2, 0);
    this.camera.rotation.x = 0;
    window.addEventListener('mousemove', this.onMouseMove.bind(this));
    this.placeLights();
    this.genererateCubes(500, 10);
    this.genererateCubes(500, 25);
    this.genererateCubes(500, 40);
  }

  protected animate() {
    this.camera.rotation.y += 0.0001;
    super.animate();
  }

  protected swapColorPalette() {
    super.swapColorPalette();
    const newColor = new Color(Constants.meshColour[(localStorage.getItem('colorTheme') || 'light')]);
    // @ts-ignore
    this.cubes.forEach(cube => gsap.to(cube.material.color, {duration: 0.5, r: newColor.r, g: newColor.g, b: newColor.b}));
  }

  private genererateCubes(amount: number, radius: number) {
    const positions: Vector3[] = [];
    for (let i = 0; i < amount; i++) {

      let color;
      const val = Math.random();
      if (val < 0.9) {
        color = Constants.meshColour[localStorage.getItem('colorTheme') || 'light'];
      } else if (val < 0.94) {
        color = 0x8c6d0e;
      } else if (val < 0.98) {
        color = 0x8a3333;
      } else {
        color = 0x0e6a8c;
      }
      const geometry = new BoxGeometry(0.5, 0.5, 0.5);
      const material = new MeshLambertMaterial({color});
      const cube = new Mesh(geometry, material);
      if (val < 0.9) {
        this.cubes.push(cube);
      }
      let trySize = 0;
      while (trySize < 5) {
        const pos = new Vector3((Math.random() - 0.5), (Math.random() - 0.5), (Math.random() - 0.5)).normalize().multiplyScalar(radius);
        let succ = true;
        for (const position of positions) {
          if (position.distanceTo(pos) < 1) {
            succ = false;
            break;
          }
        }
        if (succ) {
          positions.push(pos);
          cube.position.set(pos.x, pos.y, pos.z);
          this.scene.add(cube);
          break;
        }
        trySize++;
      }
    }
  }

  private onMouseMove(event: MouseEvent) {
    const mouseX = (event.clientX / window.innerWidth) * 2 - 1;
    const mouseY = -(event.clientY / window.innerHeight) * 2 + 1;
    this.raycaster.setFromCamera({x: mouseX, y: mouseY}, this.camera);
    for (const intersect of this.raycaster.intersectObjects(this.scene.children, true)) {
      gsap.to(intersect.object.scale, {duration: 1, x: 0.25, delay: .1, ease: 'expo.out'});
      gsap.to(intersect.object.scale, {duration: 1, y: 0.25, delay: .1, ease: 'expo.out'});
      gsap.to(intersect.object.scale, {duration: 1, z: 0.25, delay: .1, ease: 'expo.out'});
      gsap.to(intersect.object.scale, {duration: 1, x: 1, delay: 2, ease: 'elastic.out'});
      gsap.to(intersect.object.scale, {duration: 1, y: 1, delay: 2, ease: 'elastic.out'});
      gsap.to(intersect.object.scale, {duration: 1, z: 1, delay: 2, ease: 'elastic.out'});
    }
  }


  private placeLights() {
    const l1 = new PointLight(0xFFFFFF, 1, 1000);
    l1.position.set(0, 0, 0);
    this.scene.add(l1);

    const l2 = new PointLight(0xFFFFFF, 1, 1000);
    l2.position.set(0, 15, 100);
    this.scene.add(l2);
  }
}
