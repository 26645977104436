import {Component, HostBinding, Renderer2} from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'yarally-portfolio';

  constructor(private renderer: Renderer2) {
  }

  @HostBinding('class')
  get theme() {
    this.renderer.removeClass(document.body, 'dark-mode');
    this.renderer.removeClass(document.body, 'light-mode');
    const theme = (localStorage.getItem('colorTheme') || 'light');
    this.renderer.addClass(document.body, theme + '-mode');
    return theme + '-mode';
  }
}
