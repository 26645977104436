import {Component, OnInit} from '@angular/core';
import {MatSliderChange} from '@angular/material/slider';
import {ThreeComponent} from '../three/three.component';
import {ThreeService} from '../../services/three.service';
import {Router} from '@angular/router';
import {BoxGeometry, Color, Mesh, MeshLambertMaterial, PointLight} from 'three';

@Component({
  selector: 'app-hue-shifter',
  templateUrl: './hue-shifter.component.html',
  styleUrls: ['./hue-shifter.component.scss']
})
export class HueShifterComponent extends ThreeComponent implements OnInit {

  public c1 = '#2B2B2B';
  public c2 = '#555555';
  public c3 = '#747474';
  public c4 = '#8A8A8A';
  public c5 = '#B3B3B3';
  public c6 = '#E7E7E7';

  public hue = 0;
  public sat = 50;
  public val = 50;
  public shift = 0;

  private f1: any;
  private f2: any;
  private f3: any;
  private f4: any;
  private f5: any;
  private f6: any;

  constructor(protected threeService: ThreeService, protected router: Router) {
    super(threeService, router, false);
  }

  ngOnInit(): void {
    this.hue = Math.random() * 360;
    super.initialize();
    this.camera.position.set(0, -0.5, window.screen.width <= 600 ? -3 : -0.05);
    this.camera.lookAt(0, -0.5, 0);
    this.generateScene();
    this.placeLights();
    this.updateColours();
  }

  public hslToHex(h, s, l) {
    l /= 100;
    const a = s * Math.min(l, 1 - l) / 100;
    const f = n => {
      const k = (n + h / 30) % 12;
      const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
      return Math.round(255 * color).toString(16).padStart(2, '0');   // convert to Hex and prefix "0" if needed
    };
    return `#${f(0)}${f(8)}${f(4)}`;
  }

  private updateColours() {
    const valueIncrement = Math.min(this.val, 100 - this.val) / 4;
    this.c3 = this.hslToHex(this.hue, this.sat, this.val);
    this.c2 = this.hslToHex(this.hue + this.getShift(true, 1), this.sat, this.val - valueIncrement);
    this.c1 = this.hslToHex(this.hue + this.getShift(true, 2), this.sat, this.val - valueIncrement * 2);
    this.c4 = this.hslToHex(this.hue + this.getShift(false, 1), this.sat, this.val + valueIncrement);
    this.c5 = this.hslToHex(this.hue + this.getShift(false, 2), this.sat, this.val + valueIncrement * 2);
    this.c6 = this.hslToHex(this.hue + this.getShift(false, 3), this.sat, this.val + valueIncrement * 3);
    this.f1.material.color = this.hexToRgb(this.c1);
    this.f2.material.color = this.hexToRgb(this.c2);
    this.f3.material.color = this.hexToRgb(this.c3);
    this.f4.material.color = this.hexToRgb(this.c4);
    this.f5.material.color = this.hexToRgb(this.c5);
    this.f6.material.color = this.hexToRgb(this.c6);
    // gsap.to(this.f6.mesh.material.color, this.hexToRgb(this.c6));
  }

  private hexToRgb(hex) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? new Color(parseInt(result[1], 16) / 255, parseInt(result[2], 16) / 255, parseInt(result[3], 16) / 255) : null;
  }

  private getShift(dark: boolean, amount) {
    if (this.hue > 230 || this.hue < 50) {
      return dark ? -1 * Math.min(this.shift, (((this.hue - 230 % 360) + 360) % 360) / 2) * amount : Math.min(this.shift, (((this.hue - 50 % 360) + 360) % 360) / 3) * amount;
    }
    return dark ? Math.min(this.shift, (230 - this.hue) / 2) * amount : -1 * Math.min(this.shift, (this.hue - 50) / 3) * amount;

  }

  setHue(event: MatSliderChange) {
    this.hue = event.value / 100 * 360;
    this.updateColours();
  }

  setSat(event: MatSliderChange) {
    this.sat = event.value;
    this.updateColours();

  }

  setVal(event: MatSliderChange) {
    this.val = event.value;
    this.updateColours();
  }

  setShift(event: MatSliderChange) {
    this.shift = event.value;
    this.updateColours();
  }

  private placeLights() {
    const l1 = new PointLight(0xFFFFFF, 0.5, 1000);
    l1.position.set(2, -1, -1);
    this.scene.add(l1);

    const l2 = new PointLight(0xFFFFFF, 0.75, 1000);
    l2.position.set(-2, -1, -1);
    this.scene.add(l2);
  }

  private generateScene() {
    const frameGeometry = new BoxGeometry(0.25, 0.5, 0.05);
    const mat1 = new MeshLambertMaterial({color: '#eee'});
    const mat2 = new MeshLambertMaterial({color: '#eee'});
    const mat3 = new MeshLambertMaterial({color: '#eee'});
    const mat4 = new MeshLambertMaterial({color: '#eee'});
    const mat5 = new MeshLambertMaterial({color: '#eee'});
    const mat6 = new MeshLambertMaterial({color: '#eee'});
    this.f1 = new Mesh(frameGeometry, mat1);
    this.f2 = new Mesh(frameGeometry, mat2);
    this.f3 = new Mesh(frameGeometry, mat3);
    this.f4 = new Mesh(frameGeometry, mat4);
    this.f5 = new Mesh(frameGeometry, mat5);
    this.f6 = new Mesh(frameGeometry, mat6);
    this.f1.position.set(1.25, 0.2, 1.5);
    this.f2.position.set(0.75, 0.2, 1.5);
    this.f3.position.set(0.25, 0.2, 1.5);
    this.f4.position.set(-0.25, 0.2, 1.5);
    this.f5.position.set(-0.75, 0.2, 1.5);
    this.f6.position.set(-1.25, 0.2, 1.5);
    this.scene.add(this.f1);
    this.scene.add(this.f2);
    this.scene.add(this.f3);
    this.scene.add(this.f4);
    this.scene.add(this.f5);
    this.scene.add(this.f6);
  }

  formatLabel(value: number | null) {
    if (!value) {
      return 0;
    }
    return value;
  }

  copyColour(color: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = color;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }
}
