import {Component, OnInit} from '@angular/core';
import {ThreeComponent} from '../three/three.component';
import {ThreeService} from '../../services/three.service';
import {BoxGeometry, Color, Mesh, MeshLambertMaterial, PointLight, Raycaster, Vector3} from 'three';
import {Constants} from '../../constants';
import {gsap} from 'gsap';
import {Cuboid} from './cuboid';
import {Router} from '@angular/router';

@Component({
  selector: 'app-sort-visualizer',
  templateUrl: './sort-visualizer.component.html',
  styleUrls: ['./sort-visualizer.component.scss']
})
export class SortVisualizerComponent extends ThreeComponent implements OnInit {

  public running;
  public operations = 0;

  private raycaster = new Raycaster();
  private cuboids: Cuboid[] = [];
  private selectedAlgo;

  constructor(protected threeService: ThreeService, protected router: Router) {
    super(threeService, router, true);
  }

  ngOnInit(): void {
    super.initialize();
    this.camera.position.set(0, 0, 3);
    this.camera.rotation.x = 0;
    this.generateCuboids(25);
    this.placeLights();
  }

  updateSlider(value: number) {
    if (value > this.cuboids.length) {
      this.generateCuboids(value - this.cuboids.length);
    } else {
      const cubeLength = this.cuboids.length;
      for (let i = 0; i < cubeLength - value; i++) {
        this.scene.remove(this.cuboids.pop().destroy());
      }
    }
    this.updatePositions();
  }

  shuffle() {
    this.cuboids.sort((a, b) => Math.random() - 0.5);
    this.cuboids.forEach(cuboid => {
      gsap.to(cuboid.mesh.position, {
        duration: 0.5,
        x: ((-(this.cuboids.length) / 2) + this.cuboids.indexOf(cuboid)) * (0.3),
        delay: 0,
        ease: 'expo.out'
      });
    });
  }

  start() {
    switch (this.selectedAlgo) {
      case 'naive':
        return this.naiveSort();
      case 'bubble':
        return this.bubbleSort();
      case 'quick':
        return this.quickSort();
    }
  }

  setSortingAlgo(val: any) {
    this.selectedAlgo = val;
  }

  stop() {
    this.running = false;
  }

  protected animate() {
    super.animate();
    this.cuboids.forEach(cuboid => {
      cuboid.rotate();
    });
  }

  protected swapColorPalette() {
    super.swapColorPalette();
    const newColor = new Color(Constants.meshColour[(localStorage.getItem('colorTheme') || 'light')]);
    // @ts-ignore
    this.cuboids.forEach(cube => gsap.to(cube.mesh.material.color, {duration: 0.5, r: newColor.r, g: newColor.g, b: newColor.b}));
  }

  private cubeColor() {
    return new Color(Constants.meshColour[(localStorage.getItem('colorTheme') || 'light')]);
  }

  private primaryColor() {
    return new Color(0x8a3333);
  }

  private altColor() {
    return new Color(0x0e6a8c);
  }

  private generateCuboids(amount: number) {
    const finalListSize = this.cuboids.length + amount;
    this.cuboids.forEach(cuboid => {
      gsap.to(cuboid.mesh.position, {
        duration: 0.5,
        x: ((-(finalListSize) / 2) + this.cuboids.indexOf(cuboid)) * (0.3),
        delay: 0,
        ease: 'expo.out'
      });
    });
    for (let i = 0; i < amount; i++) {
      const height = 0.5 + Math.random() * 3;
      const color = new Color(Constants.meshColour[(localStorage.getItem('colorTheme') || 'light')]);
      const geometry = new BoxGeometry(0.1, height, 0.1);
      const material = new MeshLambertMaterial({color});
      const mesh = new Mesh(geometry, material);
      const pos = new Vector3(((-(finalListSize) / 2) + this.cuboids.length) * (0.3), Math.random() * 0.01 - 0.005, 0);
      mesh.position.set(pos.x, pos.y, pos.z);
      this.cuboids.push(new Cuboid(mesh, height));
      this.scene.add(mesh);
      mesh.scale.y = 0;
      gsap.to(mesh.scale, {duration: 0.5, y: 1, delay: 0.4, ease: 'elastic.out'});
    }
  }

  private placeLights() {
    const l1 = new PointLight(0xFFFFFF, 1, 1000);
    l1.position.set(-15, 15, 10);
    this.scene.add(l1);

    const l2 = new PointLight(0xFFFFFF, 1, 1000);
    l2.position.set(15, 15, 10);
    this.scene.add(l2);
  }

  private updatePositions() {
    this.cuboids.forEach(cuboid => {
      const newX = ((-(this.cuboids.length) / 2) + this.cuboids.indexOf(cuboid)) * (0.3);
      if (Math.abs(newX - cuboid.mesh.position.x) > 0.0001) {
        this.operations++;
      }
      gsap.to(cuboid.mesh.position, {
        duration: 0.5,
        x: newX,
        delay: 0,
        ease: 'expo.out'
      });
    });
    gsap.to(this.camera.position, {duration: 1, z: this.cuboids.length / 25 * 3, ease: 'expo.out'});
  }

  private async naiveSort() {
    this.operations = 0;
    this.running = true;
    let shortest: Cuboid;
    let selected: Cuboid;
    for (let i = 0; i < this.cuboids.length; i++) {
      for (let j = i; j < this.cuboids.length; j++) {
        if (!this.running) {
          return;
        }
        selected?.setColor(this.cubeColor());
        selected = this.cuboids[j];
        this.operations++;
        selected.setColor(this.primaryColor());
        if (selected.height < shortest?.height || shortest === undefined) {
          shortest?.setColor(this.cubeColor());
          shortest = selected;
          selected = undefined;
          shortest.setColor(this.altColor());
        }
        await new Promise(f => setTimeout(f, 50));
      }
      this.cuboids.splice(this.cuboids.indexOf(shortest), 1);
      this.cuboids.splice(i, 0, shortest);
      this.updatePositions();
      await new Promise(f => setTimeout(f, 250));
      shortest.setColor(this.cubeColor());
      shortest = undefined;
    }
    this.running = false;
  }

  private async bubbleSort() {
    this.operations = 0;
    let left: Cuboid;
    let right: Cuboid;
    let swapped;
    this.running = true;
    for (let i = 1; i < this.cuboids.length; i++) {
      swapped = false;
      for (let j = 0; j < this.cuboids.length - i; j++) {
        if (!this.running) {
          return;
        }
        left?.setColor(this.cubeColor());
        right?.setColor(this.cubeColor());
        left = this.cuboids[j];
        left.setColor(this.primaryColor());
        this.operations++;
        right = this.cuboids[j + 1];
        right.setColor(this.primaryColor());
        this.operations++;
        await new Promise(f => setTimeout(f, 50));
        if (left.height > right.height) {
          swapped = true;
          right.setColor(this.altColor());
          this.cuboids[j] = right;
          this.cuboids[j + 1] = left;
          await new Promise(f => setTimeout(f, 100));
          this.updatePositions();
        }
      }
      if (!swapped) {
        this.running = false;
        return;
      }
    }
    this.running = false;
  }

  private async quickSort() {
    this.operations = 0;
    this.running = true;
    await this.sortRange();
    this.running = false;
  }

  private async sortRange(first = 0, last = this.cuboids.length - 1) {
    if (first >= last) {
      return;
    }
    let left = first;
    let right = last;
    const pivot = this.cuboids[left];
    pivot.setColor(this.altColor());
    this.operations++;
    while (left !== right) {
      if (!this.running) {
        return;
      }
      const l = this.cuboids[left + 1];
      const r = this.cuboids[right];
      l.setColor(this.primaryColor());
      this.operations++;
      r.setColor(this.primaryColor());
      this.operations++;
      if (r.height >= pivot.height) {
        right--;
        this.operations++;
      } else if (l.height <= pivot.height) {
        this.cuboids[left + 1] = pivot;
        this.operations++;
        this.cuboids[left] = l;
        this.operations++;
        left++;
        this.operations++;
      } else {
        this.cuboids[left + 1] = r;
        this.operations++;
        this.cuboids[right] = l;
        this.operations++;
      }
      await new Promise(f => setTimeout(f, 200));
      this.updatePositions();
      r.setColor(this.cubeColor());
      l.setColor(this.cubeColor());
    }
    pivot.setColor(this.cubeColor());
    await this.sortRange(first, left - 1);
    await this.sortRange(left + 1, last);
  }
}
