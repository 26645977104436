import {Component, OnInit} from '@angular/core';
import {ThreeComponent} from '../three/three.component';
import {ThreeService} from '../../services/three.service';
import {Router} from '@angular/router';
import {BoxGeometry, Color, Mesh, MeshBasicMaterial, MeshLambertMaterial, PointLight, TextureLoader} from 'three';
import {Constants} from '../../constants';
import {gsap} from 'gsap';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Component({
  selector: 'app-art-display',
  templateUrl: './art-display.component.html',
  styleUrls: ['./art-display.component.scss']
})
export class ArtDisplayComponent extends ThreeComponent implements OnInit {

  private frame;
  private frame2;
  private frame3;
  private wall;
  private loader;
  public selected = 0;
  public aiImages = [];
  public vectorImages = [];
  public pixelImages = [];
  private headers = {
    headers: new HttpHeaders().set('Authorization', 'Bearer 6181bd2ee3e8c0a588244cbc178461405c19f52f')
  };
  selectedFolder: number | null = 0;

  constructor(protected threeService: ThreeService, protected router: Router, private http: HttpClient) {
    super(threeService, router, false);
  }

  ngOnInit(): void {
    this.http.get('https://api.imgur.com/3/album/FGsOLQd/images', this.headers).subscribe(res => {
      // @ts-ignore
      this.aiImages = res.data.map(r => r.link);
      this.genererateScene();
    });
    this.http.get('https://api.imgur.com/3/album/Dan2daQ/images', this.headers).subscribe(res => {
      // @ts-ignore
      this.pixelImages = res.data.map(r => r.link);
    });
    this.http.get('https://api.imgur.com/3/album/MMQq8nr/images', this.headers).subscribe(res => {
      // @ts-ignore
      this.vectorImages = res.data.map(r => r.link);
    });
    this.loader = new TextureLoader();
    super.initialize();
    this.camera.position.set(0, -0.5, -0.05);
    this.camera.lookAt(0, -0.5, 0);
    this.placeLights();
  }

  protected swapColorPalette() {
    super.swapColorPalette();
    const altColor = new Color(Constants.altMeshColour[(localStorage.getItem('colorTheme') || 'light')]);
    gsap.to(this.wall.material.color, {duration: 0.5, r: altColor.r, g: altColor.g, b: altColor.b});
  }

  private placeLights() {
    const l1 = new PointLight(0xFFFFFF, 0.5, 1000);
    l1.position.set(2, -1, -1);
    this.scene.add(l1);

    const l2 = new PointLight(0xFFFFFF, 0.75, 1000);
    l2.position.set(-2, -1, -1);
    this.scene.add(l2);
  }

  private genererateScene() {
    const texture = this.loader.load(this.aiImages[this.selected]);
    const frameMaterial = new MeshBasicMaterial({color: 0xffffff, map: texture});

    const colorAlt = Constants.altMeshColour[localStorage.getItem('colorTheme') || 'light'];
    const frameGeometry = new BoxGeometry(0.5, 0.7, 0.2);
    const wallGeometry = new BoxGeometry(10, 1.25, 0.2);
    const wallMaterial = new MeshLambertMaterial({color: colorAlt});
    this.frame = new Mesh(frameGeometry, frameMaterial);
    this.frame2 = new Mesh(frameGeometry, frameMaterial);
    this.frame3 = new Mesh(frameGeometry, frameMaterial);
    this.wall = new Mesh(wallGeometry, wallMaterial);
    this.frame.position.set(0, 0, 1.5);
    this.frame2.position.set(-5, 0, 1.5);
    this.frame3.position.set(5, 0, 1.5);
    this.wall.position.set(0, 0, 1.7);
    this.scene.add(this.frame);
    this.scene.add(this.frame3);
    this.scene.add(this.frame2);
    this.scene.add(this.wall);
  }

  public next() {
    const currFolder = this.selectedFolder === 0 ? this.aiImages : (this.selectedFolder === 1 ? this.vectorImages : this.pixelImages);
    this.setTexture((this.selected + 1) % currFolder.length, true);
  }

  public prev() {
    const currFolder = this.selectedFolder === 0 ? this.aiImages : (this.selectedFolder === 1 ? this.vectorImages : this.pixelImages);
    this.setTexture((this.selected - 1) % currFolder.length, false);
  }

  setTexture(idx: number, isNext: boolean) {
    const currFolder = this.selectedFolder === 0 ? this.aiImages : (this.selectedFolder === 1 ? this.vectorImages : this.pixelImages);
    if (idx < 0) {
      idx = currFolder.length - 1;
    }
    if ((idx % currFolder.length) === (this.selected % currFolder.length)) {
      return;
    }
    this.selected = idx;
    this.moveFrame(isNext, currFolder, true);
  }

  private moveFrame(isNext: boolean, currFolder, initial: boolean = false) {
    if (initial) {
      const texture = this.loader.load(currFolder[this.selected]);
      this.frame3.material = new MeshBasicMaterial({color: 0xffffff, map: texture});
      this.frame2.material = new MeshBasicMaterial({color: 0xffffff, map: texture});
    }
    if (isNext) {
      this.frame.position.x -= 0.15;
      this.frame3.position.x -= 0.15;
    } else {
      this.frame2.position.x += 0.15;
      this.frame.position.x += 0.15;
    }
    if (Math.abs(this.frame.position.x) < 5) {
      window.requestAnimationFrame(() => this.moveFrame(isNext, currFolder));
    } else {
      const texture = this.loader.load(currFolder[this.selected]);
      this.frame.material = new MeshBasicMaterial({color: 0xffffff, map: texture});
      this.frame.position.x = 0;
      this.frame3.position.x = 5;
      this.frame2.position.x = -5;
    }
  }
}
