<i class="fas fa-chevron-left" [ngClass]="theme" (click)="prev()"></i>
<i class="fas fa-chevron-right" [ngClass]="theme" (click)="next()"></i>
<div class="art-selection container">
  <mat-tab-group class="row" [(selectedIndex)]="selectedFolder">
    <mat-tab label="Posters">
      <div class="row m-0 p-0">
        <img *ngFor="let img of aiImages; let idx = index" [src]="img" class="col-md-4 col-12 my-3"
             (click)="setTexture(idx, idx % this.aiImages.length > this.selected % this.aiImages.length)">
      </div>
    </mat-tab>
    <mat-tab label="Vector Images">
      <div class="row m-0 p-0">
        <img *ngFor="let img of vectorImages; let idx = index" [src]="img" class="col-md-4 col-12 my-3"
             (click)="setTexture(idx, idx % this.vectorImages.length > this.selected % this.vectorImages.length)">
      </div>
    </mat-tab>
    <mat-tab label="Pixel Art">
      <div class="row m-0 p-0">
        <img *ngFor="let img of pixelImages; let idx = index" [src]="img" class="col-md-4 col-12 my-3"
             (click)="setTexture(idx, idx % this.pixelImages.length > this.selected % this.pixelImages.length)">
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
