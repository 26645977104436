<div class="container settings">
  <div class="row justify-content-center">
    <div class="col-6">
      <svg width="1000" height="1000" viewBox="0 0 1000 1000" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1_2)">
          <path class="l0" d="M625.385 999.368H375.685V937.511H249.7V874.518H188.41V812.661H124.85V750.236H61.8575V624.818H0.567501V375.685H61.8575V250.268H124.85V187.843H188.41V125.418H250.268V62.9925H376.253V0.567501H624.818V62.9925H750.803V125.418H812.661V187.843H875.086V250.268H936.943V375.685H999.368V624.818H936.943V750.236H875.086V812.661H812.661V874.518H749.668V937.511H625.385V999.368Z" fill="black"/>
          <path (click)="copyColour(c1)" matTooltip="Click to copy: {{c1}}" class="l1" d="M625.451 938.177H374.333V875.899H250.448V812.952H187.501V750.675H124.554V626.121H62.2772V376.342H124.554V251.118H187.501V187.501H250.448V124.554H375.003V62.9469H625.451V124.554H750.675V187.501H812.283V251.118H875.899V376.342H936.837V626.121H875.899V750.675H812.283V812.952H750.675V875.899H625.451V938.177Z" [attr.fill]="c1"/>
          <path (click)="copyColour(c2)" matTooltip="Click to copy: {{c2}}" class="l2" d="M875.899 251.118V376.342V562.522H812.283V626.121H750.675V687.702H687.702V750.675H625.898V812.952H561.999V875.899H374.333H250.448V812.952H187.501V750.675H124.554V626.121H62.2772V376.342H124.554V251.118H187.501V187.501H250.448V124.554H375.003V62.9469H625.451V124.554H750.675V187.501H812.283V251.118H875.899Z" [attr.fill]="c2"/>
          <path (click)="copyColour(c3)" matTooltip="Click to copy: {{c3}}" class="l3" d="M750.675 124.554V187.501V437.398H687.481V561.949H625.042V626.121H562.603V687.481H499.837V750.675H187.501H124.554V626.121H62.2772V376.342H124.554V251.118H187.501V187.501H250.448V124.554H375.003V62.9469H625.451V124.554H750.675Z" [attr.fill]="c3"/>
          <path (click)="copyColour(c4)" matTooltip="Click to copy: {{c4}}" class="l4" d="M499.846 125.138H374.943V187.354H250.524V250.04H186.952V374.657H124.886V500.081H186.952V562.767H250.524V624.984H500.081V562.767H562.532V500.081H624.984V250.04H562.532V187.354H499.846V125.138Z" [attr.fill]="c4"/>
          <path (click)="copyColour(c5)" matTooltip="Click to copy: {{c5}}" class="l5" d="M499.837 562.276H312.521V500.491H249.428V312.848H312.521V250.736H499.837V312.848H562.276V500.491H499.837V562.276Z" [attr.fill]="c5"/>
          <path (click)="copyColour(c6)" matTooltip="Click to copy: {{c6}}" class="l6" d="M437.398 499.837H374.96V437.398H312.521V312.194H437.398V375.287H499.51V437.398H437.398V499.837Z" [attr.fill]="c6"/>
        </g>
        <defs>
          <clipPath id="clip0_1_2">
            <rect width="1000" height="1000" fill="white"/>
          </clipPath>
        </defs>
      </svg>
    </div>
    <div class="col-5 row">
      <div class="col-12">
        <mat-slider color="primary" (input)="setHue($event)" value="{{hue / 360 * 100}}"></mat-slider>
        <div class="rect" [style]="'background-color: ' + hslToHex(hue, 100, 50)" [ngClass]="{'dark-mode': theme === 'dark-mode'}"></div>
      </div>
      <div class="col-12">
        <mat-slider color="primary" (input)="setSat($event)" value="50"></mat-slider>
        <div class="rect" [style]="'background-color: ' + hslToHex(hue, sat, 50)" [ngClass]="{'dark-mode': theme === 'dark-mode'}"></div>
      </div>
      <div class="col-12">
        <mat-slider color="primary" (input)="setVal($event)" value="50"></mat-slider>
        <div class="rect" [style]="'background-color: ' + hslToHex(0, 0, val)" [ngClass]="{'dark-mode': theme === 'dark-mode'}"></div>
      </div>
    </div>
    <div class="col-1">
      <mat-slider [vertical]="true" class="ml-5"
                  color="accent"
                  style="height: 80%; width: 100%"
                  thumbLabel
                  [min]="0"
                  [max]="50"
                  [displayWith]="formatLabel"
                  (input)="setShift($event)">
      </mat-slider>
    </div>
  </div>
</div>
