import {BoxGeometry, Color, Mesh, MeshLambertMaterial} from 'three';
import {gsap} from 'gsap';

export class Cuboid {
  public mesh: Mesh;
  public height: number;
  private floatDuration;
  private floatInterval;

  constructor(mesh, height) {
    this.mesh = mesh;
    this.height = height;
    this.floatDuration = 2 + Math.random() * 4;
    this.cuboidFloat();
    this.floatInterval = setInterval(() => {
      this.cuboidFloat();
    }, this.floatDuration * 1000);
  }

  public rotate() {
    const geometry = this.mesh.geometry as BoxGeometry;
    const height = geometry.parameters.height;
    if (+height.toString().charAt(3) % 2 === 0) {
      this.mesh.rotation.y += 0.005 / geometry.parameters.height;
    } else {
      this.mesh.rotation.y -= 0.005 / geometry.parameters.height;
    }
  }

  public destroy() {
    clearInterval(this.floatInterval);
    return this.mesh;
  }

  public setColor(color: Color) {
    (this.mesh.material as MeshLambertMaterial).color = color;
  }

  private cuboidFloat() {
    if (this.mesh.position.y <= 0) {
      gsap.to(this.mesh.position, {duration: this.floatDuration, y: 0.1, delay: 0, ease: 'none'});
    } else {
      gsap.to(this.mesh.position, {duration: this.floatDuration, y: -0.1, delay: 0, ease: 'none'});
    }
  }
}
