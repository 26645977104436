import {Component, HostBinding, OnInit} from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  menuActive: boolean;

  constructor() {
  }

  @HostBinding('class')
  get theme() {
    return (localStorage.getItem('colorTheme') || 'light') + '-mode';
  }

  ngOnInit(): void {
  }

  public setDarkMode(val: boolean) {
    if (val) {
      localStorage.setItem('colorTheme', 'dark');
    } else {
      localStorage.setItem('colorTheme', 'light');
    }
  }

}
